var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCardBody",
    [
      _c("alert-section", {
        attrs: {
          successAlertMessage: _vm.successAlertMessage,
          dismissSecs: _vm.dismissSecs,
          dismissSuccessAlert: _vm.dismissSuccessAlert,
          errorAlertMessage: _vm.errorAlertMessage,
          showErrorAlert: _vm.showErrorAlert,
        },
      }),
      _c(
        "CCard",
        { staticClass: "filters", attrs: { "accent-color": "warning" } },
        [
          _c(
            "CCardHeader",
            {
              staticClass: "text-warning shadow-none card-header",
              attrs: { role: "button" },
              on: { click: _vm.resetFilters },
            },
            [
              _c(
                "strong",
                { staticClass: "m-0" },
                [
                  _c("CIcon", {
                    attrs: {
                      name: `cil-filter${
                        _vm.$store.state.filterShow ? "-x" : ""
                      }`,
                    },
                  }),
                  _vm._v(" Filters"),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "card-header-actions" },
                [
                  _c("CIcon", {
                    attrs: {
                      name: `cil-chevron-${
                        _vm.$store.state.filterShow ? "bottom" : "top"
                      }`,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "CCollapse",
            { attrs: { show: _vm.$store.state.filterShow } },
            [
              _c("CCardBody", { staticClass: "p-2" }, [
                _c(
                  "div",
                  {
                    staticClass: "d-flex flex-md-row flex-column",
                    staticStyle: { gap: "0.5rem" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex-grow-1" },
                      [
                        _c("v-select", {
                          staticClass: "v-select-filter",
                          attrs: {
                            placeholder: "Select invoice type..",
                            reduce: (o) => o.value,
                            options: [
                              { value: "all", label: "All" },
                              { value: "mailed", label: "Mailed" },
                              { value: "not-mailed", label: "Not Mailed" },
                              { value: "transfered", label: "Transfered" },
                              {
                                value: "not-transfered",
                                label: "Not Transfered",
                              },
                              {
                                value: "pay-to-customer",
                                label: "Pay to Customer",
                              },
                            ],
                          },
                          model: {
                            value: _vm.selectedInvoiceFilter,
                            callback: function ($$v) {
                              _vm.selectedInvoiceFilter = $$v
                            },
                            expression: "selectedInvoiceFilter",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex-grow-1" },
                      [
                        _c("v-select", {
                          staticClass: "v-select-filter",
                          attrs: {
                            placeholder: "Select restaurant..",
                            options: _vm.allRestaurants,
                            loading:
                              _vm.loading && _vm.allRestaurants.length <= 0,
                          },
                          on: { input: _vm.restaurantFilter },
                          model: {
                            value: _vm.selectedRestaurant,
                            callback: function ($$v) {
                              _vm.selectedRestaurant = $$v
                            },
                            expression: "selectedRestaurant",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.pages > 1
                      ? _c(
                          "div",
                          [
                            _c("CPagination", {
                              attrs: {
                                align: "end",
                                dots: false,
                                pages: _vm.pages,
                                "active-page": _vm.activePage,
                              },
                              on: {
                                "update:activePage": function ($event) {
                                  _vm.activePage = $event
                                },
                                "update:active-page": function ($event) {
                                  _vm.activePage = $event
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.loading
        ? _c(
            "div",
            [
              _c("CDataTable", {
                attrs: {
                  striped: "",
                  hover: "",
                  items: _vm.loadedItems,
                  fields: _vm.fields,
                  sorter: { external: true, resetable: true },
                  "table-filter": {
                    external: true,
                    lazy: true,
                    placeholder: "...",
                  },
                  tableFilterValue: _vm.search,
                  "column-filter": { external: true, lazy: true },
                  itemsPerPageSelect: {
                    external: true,
                    values: [5, 15, 25, 50, 100, 150, 200, 250, 500],
                  },
                  "items-per-page": _vm.itemsPerPage,
                  loading: _vm.loading,
                  noItemsView: {
                    noResults: "No filtering results are available!",
                    noItems: "No invoices found!",
                  },
                },
                on: {
                  "update:tableFilterValue": function ($event) {
                    _vm.search = $event
                  },
                  "update:table-filter-value": [
                    function ($event) {
                      _vm.search = $event
                    },
                    _vm.tableSearch,
                  ],
                  "update:itemsPerPage": function ($event) {
                    _vm.itemsPerPage = $event
                  },
                  "update:items-per-page": function ($event) {
                    _vm.itemsPerPage = $event
                  },
                  "update:sorter-value": _vm.sorterValue,
                  "pagination-change": _vm.paginationChange,
                  "row-clicked": _vm.rowClicked,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "select",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "td",
                            { staticClass: "text-right" },
                            [
                              _vm.isSelectable(item)
                                ? _c("CInputCheckbox", {
                                    staticClass: "ml-1",
                                    attrs: {
                                      checked: item._selected,
                                      custom: "",
                                    },
                                    on: {
                                      "update:checked": () => _vm.select(item),
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "id",
                      fn: function ({ item }) {
                        return [
                          _c("td", [_vm._v(" #" + _vm._s(item.id) + " ")]),
                        ]
                      },
                    },
                    {
                      key: "ivMailed",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "td",
                            { staticClass: "text-center" },
                            [
                              _c("CIcon", {
                                class: `text-${
                                  item.ivMailed ? "success" : "danger"
                                }`,
                                attrs: {
                                  height: 32,
                                  name: `cil-${
                                    item.ivMailed ? "check-alt" : "x"
                                  }`,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "paid",
                      fn: function ({ item }) {
                        return [
                          item.updated
                            ? _c(
                                "td",
                                { staticClass: "text-center" },
                                [
                                  _c("CSpinner", {
                                    attrs: { color: "primary" },
                                  }),
                                ],
                                1
                              )
                            : _c("td", { staticClass: "text-center" }, [
                                _vm.isShowPaid(item)
                                  ? _c(
                                      "section",
                                      { staticClass: "is-paid" },
                                      [
                                        _vm.isShowMarkAsPaid(item)
                                          ? _c("CSwitch", {
                                              attrs: {
                                                color: "success",
                                                shape: "pill",
                                                checked:
                                                  item.restaurant
                                                    .account_balance == 0,
                                                disabled:
                                                  item.restaurant
                                                    .account_balance == 0,
                                              },
                                              on: {
                                                "update:checked": () =>
                                                  _vm.markAsPaid(item),
                                              },
                                            })
                                          : _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  item.restaurant
                                                    .account_balance * -1
                                                )
                                              ),
                                            ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                        ]
                      },
                    },
                    {
                      key: "payment",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "td",
                            [
                              item.ivPymntToCust > 0
                                ? [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("toCurrency")(
                                            item.ivPymntToCust,
                                            "remove_thousand_separator"
                                          )
                                        )
                                      ),
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("removeAllWhiteSpaces")(
                                            item.ivCustIban
                                          )
                                        )
                                      ),
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          item.ivName.replace(/\s+/g, "_")
                                        ) +
                                          "_" +
                                          _vm._s(
                                            item.restaurant
                                              ? item.restaurant.id
                                              : null
                                          )
                                      ),
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(item.ivNr) +
                                          "_derdengelden_menute_be"
                                      ),
                                    ]),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      },
                    },
                    {
                      key: "ivTotIncl",
                      fn: function ({ item }) {
                        return [
                          _c("td", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("toCurrency")(
                                    item.ivTotIncl,
                                    "remove_thousand_separator"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "ivNr",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "td",
                            [
                              _c(
                                "CLink",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.viewInvoice(item)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.ivNr) + " ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "ivDate-filter",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "input-group mb-1" }, [
                            _c("div", { staticClass: "input-group-prepend" }, [
                              _c(
                                "span",
                                { staticClass: "input-group-text p-1 w-min" },
                                [_vm._v("From")]
                              ),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filters.invoices.startDate,
                                  expression: "filters.invoices.startDate",
                                },
                              ],
                              staticClass: "w-min",
                              attrs: { id: "date-filter-from", type: "date" },
                              domProps: {
                                value: _vm.filters.invoices.startDate,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.filters.invoices,
                                    "startDate",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "input-group" }, [
                            _c("div", { staticClass: "input-group-prepend" }, [
                              _c(
                                "span",
                                { staticClass: "input-group-text p-1 w-min" },
                                [_vm._v("To")]
                              ),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filters.invoices.endDate,
                                  expression: "filters.invoices.endDate",
                                },
                              ],
                              staticClass: "w-min",
                              attrs: { id: "date-filter-from", type: "date" },
                              domProps: { value: _vm.filters.invoices.endDate },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.filters.invoices,
                                    "endDate",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "ivDate",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "td",
                            {
                              class: _vm.isToday(item.ivDate)
                                ? "text-danger font-weight-bold"
                                : "",
                            },
                            [_vm._v(" " + _vm._s(item.ivDate) + " ")]
                          ),
                        ]
                      },
                    },
                    {
                      key: "restaurant",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "td",
                            [
                              _vm._v(
                                " #" +
                                  _vm._s(
                                    item.restaurant ? item.restaurant.id : null
                                  ) +
                                  " "
                              ),
                              _c(
                                "CLink",
                                {
                                  staticClass: "font-weight-bold",
                                  attrs: {
                                    target: "_blank",
                                    to: {
                                      name: "View Restaurant",
                                      params: {
                                        id: item.restaurant
                                          ? item.restaurant.id
                                          : null,
                                      },
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.restaurant
                                          ? item.restaurant.restaurant_name
                                          : null
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "txn",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "td",
                            [
                              item.bank_transaction
                                ? _c(
                                    "CButton",
                                    {
                                      directives: [
                                        {
                                          name: "c-tooltip",
                                          rawName: "v-c-tooltip",
                                          value: {
                                            content: `Download TXN (#${item.txn_id})`,
                                          },
                                          expression:
                                            "{ content: `Download TXN (#${item.txn_id})` }",
                                        },
                                      ],
                                      attrs: { size: "sm", color: "link" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadTxnFile(
                                            item.bank_transaction.file_name
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("CIcon", {
                                        attrs: { name: "cil-transfer" },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "actions",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "td",
                            { staticClass: "py-2 text-center" },
                            [
                              _c(
                                "CButton",
                                {
                                  directives: [
                                    {
                                      name: "c-tooltip",
                                      rawName: "v-c-tooltip",
                                      value: {
                                        html: true,
                                        content: "Download",
                                      },
                                      expression:
                                        "{\n              html: true,\n              content: 'Download'\n            }",
                                    },
                                  ],
                                  attrs: {
                                    size: "sm",
                                    color: "primary",
                                    shape: "pill",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.viewInvoice(item)
                                    },
                                  },
                                },
                                [
                                  _c("CIcon", {
                                    attrs: { name: "cil-cloud-download" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "CButton",
                                {
                                  directives: [
                                    {
                                      name: "c-tooltip",
                                      rawName: "v-c-tooltip",
                                      value: {
                                        html: true,
                                        content: "Send E-Mail",
                                      },
                                      expression:
                                        "{\n              html: true,\n              content: 'Send E-Mail'\n            }",
                                    },
                                  ],
                                  staticClass: "ml-1",
                                  attrs: {
                                    size: "sm",
                                    color: "info",
                                    shape: "pill",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.sendInvoice(item)
                                    },
                                  },
                                },
                                [_c("CIcon", { attrs: { name: "cil-send" } })],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  133992258
                ),
              }),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "mb-4" },
            [_c("CSpinner", { attrs: { color: "primary" } })],
            1
          ),
      _c(
        "CToaster",
        {
          staticClass: "mb-5 mr-4",
          attrs: { position: "bottom-right", autohide: 3000 },
        },
        [
          _vm._l(_vm.toasts, function (toast) {
            return [
              _c(
                "CToast",
                {
                  key: "toast" + toast,
                  attrs: { show: true, color: "warning" },
                },
                [_vm._v(" ☝ " + _vm._s(_vm.toastMessage) + " ")]
              ),
            ]
          }),
        ],
        2
      ),
      _c(
        "CCard",
        { staticClass: "actions sticky-bottom" },
        [
          _c("CCardBody", { staticClass: "p-2" }, [
            _c(
              "div",
              {
                staticClass: "d-flex flex-wrap align-items-center",
                staticStyle: { gap: "0.75rem" },
              },
              [
                this.txnId && _vm.selectedInvoiceFilter == "pay-to-customer"
                  ? _c(
                      "div",
                      { staticClass: "action d-flex flex-gap-2" },
                      [
                        _c(
                          "CLink",
                          {
                            staticClass: "mb-1",
                            on: { click: _vm.selectAllItems },
                          },
                          [_c("CIcon", { attrs: { name: "cil-library-add" } })],
                          1
                        ),
                        _c(
                          "CLink",
                          { on: { click: _vm.deselectItems } },
                          [_c("CIcon", { attrs: { name: "cil-library" } })],
                          1
                        ),
                        _vm.selectedItems.length
                          ? _c(
                              "CButton",
                              {
                                attrs: {
                                  size: "sm",
                                  color: "info",
                                  shape: "pill",
                                },
                                on: { click: _vm.markAllAsPaid },
                              },
                              [_vm._v(" Mark as Paid ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", [
                  _vm.selectedItems.length
                    ? _c("h5", { staticClass: "mt-1" }, [
                        _vm._v("Selected: "),
                        _c(
                          "span",
                          { staticClass: "d-inline count bg-primary pb-1" },
                          [
                            _vm._v(
                              _vm._s(_vm.selectedItems.length) +
                                "/" +
                                _vm._s(_vm.total)
                            ),
                          ]
                        ),
                      ])
                    : _c("h5", { staticClass: "mt-1" }, [
                        _vm._v("Total: "),
                        _c(
                          "span",
                          { staticClass: "d-inline count bg-primary pb-1" },
                          [_vm._v(_vm._s(_vm.total))]
                        ),
                      ]),
                ]),
                _c(
                  "div",
                  { staticClass: "action" },
                  [
                    _c(
                      "CButton",
                      {
                        attrs: { size: "sm", color: "success" },
                        on: {
                          click: function ($event) {
                            _vm.makeInvoicesModal = true
                          },
                        },
                      },
                      [
                        _c("CIcon", { attrs: { name: "cil-send" } }),
                        _vm._v(" Create Invoices "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "action" },
                  [
                    _c(
                      "CButton",
                      {
                        directives: [
                          {
                            name: "c-tooltip",
                            rawName: "v-c-tooltip",
                            value: {
                              html: true,
                              content: `Download All Filtered Invoices`,
                            },
                            expression:
                              "{\n              html: true,\n              content: `Download All Filtered Invoices`\n            }",
                          },
                        ],
                        attrs: {
                          size: "sm",
                          color: "primary",
                          disabled: _vm.total == 0,
                        },
                        on: { click: _vm.downloadZip },
                      },
                      [
                        _c("CIcon", { attrs: { name: "cil-cloud-download" } }),
                        _vm._v(" Download All "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "action" },
                  [
                    _c(
                      "CButton",
                      {
                        attrs: { size: "sm", color: "info", variant: "ghost" },
                        on: { click: _vm.transferInvoice },
                      },
                      [
                        _c("CIcon", {
                          attrs: { name: "cil-data-transfer-up" },
                        }),
                        _vm._v(" Transfer All Untransferred Invoices "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.selectedInvoiceFilter == "not-mailed"
                  ? _c(
                      "div",
                      [
                        _c(
                          "CButton",
                          {
                            attrs: {
                              size: "sm",
                              color: "info",
                              variant: "ghost",
                            },
                            on: { click: _vm.sendAllUnmailedInvoices },
                          },
                          [
                            _c("CIcon", { attrs: { name: "cil-send" } }),
                            _vm._v(" Send All Unmailed Invoices by Email "),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.pages > 1
                  ? _c(
                      "div",
                      { staticClass: "ml-auto" },
                      [
                        _c("CPagination", {
                          attrs: {
                            align: "center",
                            dots: false,
                            pages: _vm.pages,
                            "active-page": _vm.activePage,
                          },
                          on: {
                            "update:activePage": function ($event) {
                              _vm.activePage = $event
                            },
                            "update:active-page": function ($event) {
                              _vm.activePage = $event
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]),
        ],
        1
      ),
      _c(
        "CModal",
        {
          staticClass: "make-invoices",
          attrs: {
            title: "Create Invoices",
            size: "sm",
            color: "white",
            show: _vm.makeInvoicesModal,
          },
          on: {
            "update:show": function ($event) {
              _vm.makeInvoicesModal = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "CButton",
                    {
                      attrs: { color: "link" },
                      on: {
                        click: function ($event) {
                          _vm.makeInvoicesModal = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "CButton",
                    {
                      attrs: {
                        disabled: _vm.selectedDate === null,
                        color: "info",
                        shape: "pill",
                      },
                      on: { click: _vm.makeInvoices },
                    },
                    [
                      _c("CIcon", { attrs: { name: "cil-send" } }),
                      _vm._v(" Create invoices "),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("v-date-picker", {
            attrs: {
              mode: "date",
              "title-position": "left",
              "is-inline": "",
              "is-expanded": "",
              "first-day-of-week": 1,
              "disabled-dates": { weekdays: [1, 2, 3, 4, 5, 6] },
              "is-required": "",
            },
            model: {
              value: _vm.selectedDate,
              callback: function ($$v) {
                _vm.selectedDate = $$v
              },
              expression: "selectedDate",
            },
          }),
        ],
        1
      ),
      _c("CModal", {
        staticClass: "modal-txn",
        attrs: {
          title: "SEPA file created.",
          size: "sm",
          show: _vm.txnModal,
          closeOnBackdrop: false,
          centered: "",
        },
        on: {
          "update:show": function ($event) {
            _vm.txnModal = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "CButton",
                  {
                    attrs: { to: { name: "Bank Transactions" }, color: "link" },
                  },
                  [_vm._v("View All")]
                ),
                _c(
                  "CButton",
                  {
                    attrs: { size: "sm", color: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.downloadTxnFile(_vm.txnFileName)
                      },
                    },
                  },
                  [
                    _c("CIcon", { attrs: { name: "cil-cloud-download" } }),
                    _vm._v(" Download"),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("mc-spinner", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.submitted,
            expression: "submitted",
          },
        ],
        attrs: { opacity: 0.8, mtop: 32 + _vm.mcSpinnerMarginTop + "px" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }